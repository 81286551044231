import { Box, Grid } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Buttons,
  ImageIcon,
  Section2,
  TypographyText,
} from "../ReusableComponent/Reusab";
import Homesection2 from "./Homesection2";
const Homesection = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Grid
        container
        spacing={5}
        sx={{
          pl: "12%",
          pr: "12%",
          pb: "10%",
          pt: isSmallScreen ? "25%" : "9%",
          backgroundImage:
            "url(https://ryse.radiantthemes.com/wp-content/uploads/2020/03/banner-bg.png)",
          backgroundRepeat: "none",
        }}
      >
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ margin: "auto" }}>
          {!isSmallScreen ? (
            <>
              <div style={{ position: "absolute", top: "10%", left: "0%" }}>
                <img
                  src="https://ryse.radiantthemes.com/wp-content/uploads/2020/03/1-circle-large.png"
                  alt="circle_large"
                  style={{ marginLeft: "100px" }}
                  className="circle_small"
                />
              </div>
              <div style={{ position: "absolute", top: "60%", left: "10%" }}>
                <img
                  src="https://ryse.radiantthemes.com/wp-content/uploads/2020/03/1-circle-small.png"
                  alt="home"
                  style={{ marginLeft: "200px" }}
                  className="circle_small"
                />
              </div>
              <div
                style={{ position: "absolute", left: "100px", top: "500px" }}
              >
                <img
                  src="https://ryse.radiantthemes.com/wp-content/uploads/2020/03/banner-leaf11.png"
                  alt="home_bannana"
                  className="bannan"
                  width="200px"
                />
              </div>
            </>
          ) : (
            ""
          )}
          <TypographyText
            Typography={<>Vedaham Business Solutions</>}
            color="#1E1666"
            fontSize={isSmallScreen ? "30px" : "40px"}
            fontWeight="600"
            textAlign={!isSmallScreen ? "left" : "center"}
          />
          <br />
  
          <hr
            width="50px"
            color="red"
            size="5"
            style={{ margin: !isSmallScreen ? 0 : "auto" }}
          ></hr>
          <br />
    
          <TypographyText
            Typography={
              <>Where visions seamlessly transition into digital realitie </>
            }
            color="#6A7C92"
            textAlign="left"
            zIndex="1000"
          />
          <br />
          <br />
          {/* Button */}
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ margin: "auto" }}>
          <img
            src="https://ryse.radiantthemes.com/wp-content/uploads/2020/03/banner-image-new.png"
            alt="banner"
            width="100%"
          />
          <div style={{ position: "absolute", top: "50%", right: 0 }}>
            <img
              src="https://ryse.radiantthemes.com/wp-content/uploads/2020/03/1-circle-small.png"
              alt="circle_small"
              style={{ marginRight: "150px" }}
              className="circle_small"
            />
          </div>
          <div style={{ position: "absolute", top: "20%", right: "0%" }}>
            <img
              src="https://ryse.radiantthemes.com/wp-content/uploads/2020/03/1-circle-large.png"
              alt="circle_large"
              style={{ marginRight: "100px" }}
              className="circle_small"
            />
          </div>
          <div style={{ position: "absolute", top: "10%", left: "55%" }}>
            <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
              <img
                src="https://ryse.radiantthemes.com/wp-content/uploads/2020/03/2-plain.png"
                alt="plain"
                className="bannan"
              />
            </div>
          </div>
        </Grid>
      </Grid>

      <Homesection2 />

      <ImageIcon
        txt="Featured Services"
        icon="https://ryse.radiantthemes.com/wp-content/uploads/2020/03/home-ten-service-icon.png"
      />
    </>
  );
};

export default Homesection;
