

import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import gi from "../Assets/man-woman-stand-near-big-smartphone_81522-143-removebg-preview.png";
import fm from "../Assets/fm.png";
import Toy from "../Assets/toys.png";
import sy from "../Assets/sy.png";
import re from "../Assets/revir.png";
import Image1 from "../Assets/gradient-ui-ux-background_23-2149065783-removebg-preview.png";
import {
  AllCards,
  Buttons,
  CardsSection,
  MetaComponent,
  Section2,
  TypographyText,
} from "../ReusableComponent/Reusab";
import { Link } from "react-router-dom";
function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}
const Mobile = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>

       <MetaComponent 
       dynamicTitle="Mobile App Development in UAE | iOS Android App Developer" 
       dynamicKeywords="Get a quote from the Top Mobile App Development Company in UAE.  At Vedaham Business Solutions, we understand the role a mobile application play in today's digital world." 
       dynamicDescription="Get a quote from the Top Mobile App Development Company in UAE.  At Vedaham Business Solutions, we understand the role a mobile application play in today's digital world." /> 
      <CardsSection
        backgroundImage="https://ryse.radiantthemes.com/wp-content/uploads/2020/12/banner-bg.png"
        txt1="Mobile App Development"
        txt2="Empower your business and engage your audience with bespoke mobile solutions tailored to your unique needs.​"
        imges={Image1}
      />

      <Grid container spacing={5} sx={{ pt: "6%", pl: "6%", pr: "6%" }}>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ margin: "auto" }}>
          <img
            src="https://img.freepik.com/premium-vector/app-development-concept-with-people-scene-vector-illustration_198565-2473.jpg?w=900"
            alt=""
            width="100%"
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ margin: "auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TypographyText
                Typography={
                  <>Unlocking Digital Potential with Mobile App Development</>
                }
                fontWeight="bolder"
                color="#1E1666"
                fontSize="30px"
                // variant={!isSmallScreen ? "h5" : "h5"}
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
            </Grid>
            <Grid item xs={12}>
              <hr
                width="50px;"
                color="red"
                size="5"
                style={{ margin: !isSmallScreen ? "0" : "auto" }}
              ></hr>
            </Grid>

            <Grid item xs={12}>
              <TypographyText
                Typography={
                  <>
                    At Vedaham Business Solutions, we understand the pivotal
                    role that mobile applications play in today's digital
                    landscape. With the exponential growth of smartphone usage,
                    having a presence on mobile platforms is no longer optional
                    – it's imperative for businesses to thrive and stay ahead of
                    the competition.
                    <br />
                    <br />
                    Our Mobile App Development services are tailored to meet the
                    diverse needs of businesses across various industries.
                    Whether you're a startup looking to disrupt the market or an
                    established enterprise aiming to enhance customer
                    engagement, we have the expertise to transform your vision
                    into a feature-rich and user-friendly mobile app.
                  </>
                }
                fontSize="15px"
                color="#6A7C92"
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
              <br />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Section2
        pt="5%"
        text1="Why Choose Vedaham for Mobile App Development?"
        text2="Partner with Vedaham Business Solutions to embark on a transformative journey towards mobile excellence, and let us empower your business for success in the digital age."
      />

      <Grid
        container
        spacing={3}
        sx={{
          p: "6%",
          backgroundImage:
            "url(https://ryse.radiantthemes.com/wp-content/uploads/2019/06/web-design-service-banner.png)",
          backgroundRepeat: "no-repeat",
        }}
      >
        {Data.map((data, index) => (
          <Grid item xs={12} lg={4} md={4} sm={12}>
            <AllCards
              cardheight="200px"
              image={data.image}
              height="70px"
              width="70px"
              backgroundColor="#FFFFFF"
              boxShadow="9px 17px 30px 0px rgba(0,0,0,.1)"
              backgroundColor1="#FFFFFF"
              txt1color="#1E1666"
              txt2color="#6a7c92"
              txt1fontSize="22px"
              txt2fontSize="15px"
              Typography1={data.Typography1}
              Typography2={data.Typography2}
              txt1fontWeight="bolder"
              fontWeight="600"
              color="red"
              txt2textAlign="left"
            />
          </Grid>
        ))}

      </Grid>
      <Section2
        pt="5%"
        text1="Navigating Innovation: Our Mobile App Development Journey"
        text2="Embark on a transformative voyage with Vedaham Business Solutions as we unveil our meticulous approach to crafting exceptional mobile applications. From inception to deployment, our Mobile App Development Process is a testament to innovation, precision, and client-centricity."
      />
      <Grid
        container
        spacing={3}
        sx={{
          p: "6%",
          backgroundImage:
            "url(https://ryse.radiantthemes.com/wp-content/uploads/2019/06/web-design-service-banner.png)",
          backgroundRepeat: "no-repeat",
        }}
      >
          {Data2.map((data, index) => (
          <Grid item xs={12} lg={3} md={6} sm={6}>
            <AllCards
                cardheight="200px"
              height="70px"
              width="70px"
              backgroundColor="#FFFFFF"
              boxShadow="9px 17px 30px 0px rgba(0,0,0,.1)"
              backgroundColor1="#FFFFFF"
              txt1color="#1E1666"
              txt2color="#6a7c92"
              txt1fontSize="22px"
              txt2fontSize="15px"
              Typography1={data.Typography1}
              Typography2={data.Typography2}
              txt1fontWeight="bolder"
              fontWeight="600"
              color="red"
              txt2textAlign="left"
            />
          </Grid>
        ))}
        <Grid item lg={7} md={7} sm={12} xs={12} sx={{ margin: "auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TypographyText
                Typography={<>Let's Build Your Next Mobile App</>}
                color="#1E1666"
                fontSize="30px"
                fontWeight="600"
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
            </Grid>
            <Grid item xs={12}>
              <hr
                width="50px;"
                color="red"
                size="5"
                style={{ margin: !isSmallScreen ? 0 : "auto" }}
              ></hr>
            </Grid>
            <Grid item xs={12}>
              <br />
              <TypographyText
                Typography={
                  <>
                    Whether you're looking to streamline business processes,
                    enhance customer engagement, or launch a disruptive new
                    product, Vedaham Business Solutions is here to turn your
                    mobile app aspirations into reality. Get in touch with us
                    today to discuss your project requirements and take the
                    first step towards digital success.
                  </>
                }
                color="#6A7C92"
                textAlign="left"
              />
              <br />
            </Grid>
            
            
          </Grid>
        </Grid>
        <Grid item lg={5} md={5} sm={12} xs={12} sx={{ margin: "auto" }}>
          <img
            src={gi}
            alt=""
            width="100%"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Mobile;

const Data = [
  {
    image:
      "https://img.freepik.com/free-vector/smartwatches-mobile-apps-development-abstract-concept-illustration_335657-3857.jpg?t=st=1710950344~exp=1710953944~hmac=fca0817418572f2e29162b5504edb4dd763edbabee9320b0e5e648b6d612b452&w=740",
    Typography1: "Expertise",
    Typography2: (
      <>
        Backed by a team of seasoned professionals, we boast extensive
        experience in developing mobile applications across multiple platforms,
        ensuring unparalleled quality and performance.
        <br />
        <br />
        <br />
      </>
    ),
  },
  {
    image:
      "https://img.freepik.com/free-vector/user-interface-development-isometric-concept-with-young-woman-creating-custom-design-mobile-application-vector-illustration_1284-72341.jpg?t=st=1710950456~exp=1710954056~hmac=33c2998a148e456aa9a10b334bca1fe9d45f236b32d763833400548b1548b708&w=740",
    Typography1: "Customization",
    Typography2: (
      <>
        We understand that each business is unique, which is why we offer
        tailor-made solutions that align perfectly with your objectives and
        resonate with your target audience.
        <br />
        <br />
        <br /> <br />
      </>
    ),
  },
  {
    image:
      "https://img.freepik.com/free-vector/gradient-ui-ux-background_23-2149051191.jpg?t=st=1710950495~exp=1710954095~hmac=4136677d26563e95f9c1eb411b51563714e8b5b04a3d0fa80db191a7342f5139&w=900",
    Typography1: "User-Centric Design",
    Typography2: (
      <>
        With a focus on user experience, we employ intuitive design principles
        and conduct rigorous testing to create apps that not only look
        impressive but also deliver seamless navigation and functionality.
        <br />
        <br />
        <br />
      </>
    ),
  },
];
const Data2 = [
  {
    
    Typography1: "Discovery & Planning",
    Typography2: (
      <>
        Backed by a team of seasoned professionals, we boast extensive
        experience in developing mobile applications across multiple platforms,
        ensuring unparalleled quality and performance.
    <br/>    <br/>    
      </>
    ),
  },
  {
    Typography1: "Design & Prototyping",
    Typography2: (
      <>
       Our designers create wireframes and prototypes to visualize the app's layout, features, and user flow.
       <br/>    <br/>          <br/>    <br/> <br/></>
    ),
  },
  {
    
    Typography1: "Development & Testing",
    Typography2: (
      <>
 Our developers bring the design to life, writing clean and scalable code while adhering to industry standards. Rigorous testing ensures that the app performs flawlessly across different devices and platforms.
 <br/>
      </>
    ),
  },
  {
    
    Typography1: "Deployment & Support",
    Typography2: (
      <>
       We assist you in deploying the app to the App Store or Google Play Store and provide ongoing maintenance and support to address any issues and implement updates as needed.
       <br/>    <br/>  </>
    ),
  },
];
