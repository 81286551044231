import React from "react";
import {
  AllCards,
  Buttons,
  ImageIcon,
  Section2,
  TypographyText,
} from "../ReusableComponent/Reusab";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Slider, { sliderClasses } from "@mui/material/Slider";
import useMediaQuery from "@mui/material/useMediaQuery";
const Homesection4 = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Grid
        container
        spacing={4}
        sx={{
          pl: "6%",
          pr: "6%",
          pb: "2%",
        }}
      >
        <Grid item xs={12} lg={5} md={5} sm={12}>
          <ImageIcon
            txt="Company Skills"
            icon="
            https://ryse.radiantthemes.com/wp-content/uploads/2020/03/company-skills-1.png"
          />
          <br /> <br />
          <TypographyText
            Typography={"Elevating Businesses through Digital Mastery:"}
            color="#1E1666"
            fontSize={!isSmallScreen ? "30px" : "25px"}
            fontWeight="600"
            textAlign={!isSmallScreen ? "left" : "centre"}
          />
          <br />
          <hr
            width="100px;"
            color="red"
            size="5"
            style={{ margin: !isSmallScreen ? 0 : "auto" }}
          ></hr>
          <br />
          <TypographyText
            Typography={
              <>
                At Vedaham Business Solutions, we thrive on our diverse skill
                set and unwavering dedication to excellence. Our core
                competencies serve as the cornerstone of our success, empowering
                us to deliver exceptional digital solutions that propel
                businesses forward in today's competitive market.
                <br />
              </>
            }
            color="#6A7C92"
            textAlign="left"
          />
          <br />
          {slider.map((data, index) => (
            <div key={index}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TypographyText
                  Typography={data.txt}
                  fontWeight="600"
                  color="black"
                  textAlign="left"
                  fontSize="1.1rem"
                />
                <TypographyText
                  Typography={data.txt1}
                  fontWeight="600"
                  color="black"
                  textAlign="right"
                  fontSize="1.1rem"
                />
              </div>

              <Slider
                defaultValue={data.value}
                aria-label="Small"
                sx={{ color: data.color, p: "10px" }}
              />
            </div>
          ))}
        </Grid>

        <Grid item xs={12} lg={7} md={7} sm={12}>
          <Grid container spacing={2}>
            {Data.map((data, index) => (
              <Grid item xs={12} lg={6} md={6} sm={6} key={index}>
                <AllCards
                  boxShadow="none"
                  image={data.image}
                  height="60px"
                  width="60px"
                  backgroundColor="transparent"
                  txt1color="#1E1666"
                  txt2color="#6a7c92"
                  txt1fontSize="20px"
                  txt2fontSize="15px"
                  Typography1={data.Typography1}
                  Typography2={data.Typography2}
                  txt1fontWeight="bolder"
                  txt2textAlign="centre"
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{
          pl: "6%",
          pr: "6%",
          pb: "6%",

          backgroundImage:
            "url(http://ryse.radiantthemes.com/wp-content/uploads/2020/03/blog_banner.png)",
          backgroundRepeat: "no-repeat",
          objectFit: "cover",
          backgroundPosition: "bottom",
        }}
      >
        <Grid item xs={12}>
          <ImageIcon
            txt="Latest Blog Post"
            icon="
            https://ryse.radiantthemes.com/wp-content/uploads/2020/03/latest-blog-posts-1.png"
          />
          <Section2
            pt="1%"
            text1="Trending Blogs & Articles"
            text2={
              <>
                Your gateway to trending blogs and articles. Discover insightful
                content tailored to empower your business journey and stay ahead
                in the dynamic market landscape.
              </>
            }
          />
          <div style={{ position: "absolute", right: 0, top: "150vh" }}>
            <img
              src="https://ryse.radiantthemes.com/wp-content/uploads/2020/03/1-circle-small.png"
              alt=""
              style={{ marginRight: "150px" }}
            />
          </div>
          <div style={{ position: "absolute", left: "300", top: "120vh" }}>
            <img
              src="https://ryse.radiantthemes.com/wp-content/uploads/2020/03/1-circle-large.png"
              alt=""
              style={{ marginRight: "100px" }}
            />
          </div>
        </Grid>
        {Data2.map((data, index) => (
          <Grid item xs={12} lg={4} md={4} sm={12} key={index}>
            <AllCards
              cardheight="250px"
              image={data.image}
              height="200px"
              width="200px"
              backgroundColor={data.backgroundColor}
              boxShadow="9px 17px 30px 0px rgba(0,0,0,.1)"
              backgroundColor1="#FFFFFF"
              txt1color="#1E1666"
              txt2color="#6a7c92"
              txt1fontSize="20px"
              txt2fontSize="15px"
              Typography1={data.Typography1}
              Typography2={data.Typography2}
              txt1fontWeight="bolder"
              fontWeight="600"
              color="red"
              // txtTypography1={data.txtTypography1}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Homesection4;

const slider = [
  { value: 97, txt: "Website Development", color: "#D10B4B", txt1: "97%" },
  { value: 96, txt: "Mobile App Development", color: "#F9C100", txt1: "96%" },
  { value: 98, txt: "Graphics Design", color: "darkblue", txt1: "98%" },
  { value: 97, txt: "Digital Marketing", color: "#D10B4B", txt1: "97%" },
];

const Data = [
  {
    image:
      "https://ryse.radiantthemes.com/wp-content/uploads/2020/03/latest-technology-1.png",
    Typography1: "Latest Technology",
    Typography2:
      "We leverage cutting-edge technologies like artificial intelligence and blockchain to craft innovative digital solutions tailored to our clients' needs.",
  },
  {
    image:
      "https://ryse.radiantthemes.com/wp-content/uploads/2020/03/customer-support-1.png",
    Typography1: "High Performance",
    Typography2:
      "Our unwavering commitment to client satisfaction drives us to offer round-the-clock support, ensuring prompt assistance and uninterrupted collaboration.",
  },
  {
    image:
      "https://ryse.radiantthemes.com/wp-content/uploads/2020/03/high-performance-1.png",
    Typography1: "24x7 Customer Support",
    Typography2:
      "We prioritize performance optimization in every project, delivering solutions that exceed expectations and elevate user experiences.",
  },

  {
    image:
      "https://ryse.radiantthemes.com/wp-content/uploads/2020/03/dedicated-employee-1.png",
    Typography1: "Dedicated Employees",
    Typography2:
      "Our team consists of passionate professionals dedicated to delivering excellence in every endeavor, ensuring the success of our clients' projects.",
  },
];

const Data2 = [
  {
    txtTypography1: "March 18, 2008",
    image:
      "https://img.freepik.com/free-vector/goal-achievement-teamwork-business-concept-career-growth-cooperation-development-project_107791-29.jpg?t=st=1709647626~exp=1709651226~hmac=06855bce70cc7fb654f7c8e795a8d7bdf56168b7c024375b4f509cc331a82135&w=740",
    Typography1:
      "Empowering Businesses: Vedaham's Digital Transformation Journey",
    Typography2:
      "Explore how Vedaham Business Solutions leads in digital transformation, showcasing case studies and client testimonials that highlight their innovative solutions and impact on businesses",
  },
  {
    txtTypography1: "March 18, 2008",
    image:
      "https://img.freepik.com/free-vector/design-process-concept-landing-page_52683-25345.jpg?t=st=1709648234~exp=1709651834~hmac=fc673fdf2b6e17e0a6518723cb2123d3d8a82ee1c7ba2692a4469af341e153a4&w=900",
    Typography1:
      "Crafting Compelling Narratives: Presentation Design by Vedaham",
    Typography2: (
      <>
        <br />
        Highlight Vedaham's expertise in presentation design, focusing on their
        ability to engage audiences and drive meaningful communication through
        visually appealing presentations.
        <br />
      </>
    ),
  },
  {
    // txtTypography1: "March 18, 2008",
    image:
      "https://img.freepik.com/free-vector/brand-typographic-header-business-specialist-create-unique-design-company-brand-recognition-as-marketing-strategy-promotion-isolated-flat-illustration_613284-788.jpg?t=st=1709647113~exp=1709650713~hmac=895dc83cad596b14d130fc24efe7719d9d5f0509159ffc5cd513aaa227c90eec&w=1380",
    Typography1: "Unveiling Vedaham's Branding Excellence",
    Typography2: (
      <>
        <br />
        Discuss Vedaham's branding solutions and their role in helping
        businesses establish strong visual identities. Showcase success stories
        and the process behind creating distinctive logos and branding
        materials.
        <br />
      </>
    ),
  },
];
