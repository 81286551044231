import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  AllCards,
  Buttons,
  CardsSection,
  LeftRigt,
  MetaComponent,
  Section2,
  TypographyText,
} from "../ReusableComponent/Reusab";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
const Linkgenration = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const Data = [
    {
      img: "https://static.vecteezy.com/system/resources/previews/000/673/523/original/vector-abstract-presentation-templates-design-set.jpg",
      txt1: "Ready to Elevate Your Presentations",

      listContent: [
        {
          listtxt: "1. Call to Action:",
          listtxt1:
            <>
              Inviting viewers to reach out for personalized presentation design services.<br/>

Are you ready to take your presentations to the next level? Contact us today to learn how we can help you craft compelling visual stories that captivate your audience.
            </>
        },
        {
          listtxt: "2. Contact Information",
          listtxt1:
            <>Providing details for inquiries or consultations.<br/>

            Contact us for more information or to schedule a consultation. We're here to help you succeed with visually stunning presentations that leave a lasting impression.</>
        },
        // Add more items as needed
      ],
    },
  ];
  return (
    <>
        <MetaComponent dynamicTitle="Presentation Professional Design | Business Presentation Design" 
        dynamicKeywords="Want to create a stunning presentation for your next meeting with your client? Vedaham Business Solutions is the best choice to make attractive presentations to 'awe' impress your client" 
        dynamicDescription="Want to create a stunning presentation for your next meeting with your client? Vedaham Business Solutions is the best choice to make attractive presentations to 'awe' impress your client" /> 
      <CardsSection
        backgroundImage="https://ryse.radiantthemes.com/wp-content/uploads/2020/12/banner-bg.png"
        txt1="Presentation Design"
        txt2="Empower your message with our expert presentation design services, crafted to transform your ideas into captivating narratives that engage and inspire.​"
        imges="https://ryse.radiantthemes.com/wp-content/uploads/2020/12/seo-link.png"
      />

      <Grid
        container
        spacing={2}
        sx={{
          p: "6%",
        }}
      >
        <Grid item lg={5} md={5} sm={12} xs={12} sx={{ margin: "auto" }}>
          <br /> <br />
          <img
            src="https://fiverr-res.cloudinary.com/images/q_auto,f_auto/gigs/243930128/original/34285185272a86985b4eedfe3b4752cd2f00997d/design-powerpoint-presentation-template-and-business-ppt-slides.png"
            alt=""
            width="100%"
          />
        </Grid>
        <Grid item lg={6} md={6} xs={12} sm={12}>
          <Grid container spacing={3}>
            <br />
            <Grid item xs={12}>
              <TypographyText
                Typography="Crafting Compelling Narratives through Strategic Presentation Design"
                color="#1E1666"
                fontSize="30px"
                fontWeight="600"
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
            </Grid>
            <Grid item xs={12}>
              <hr
                width="50px;"
                color="red"
                size="5"
                style={{ margin: !isSmallScreen ? 0 : "auto" }}
              ></hr>
            </Grid>
            <Grid item xs={12}>
              <br />
              <TypographyText
                Typography={
                  <>
                    Crafting an impactful presentation design is essential for
                    effectively conveying your message and captivating your
                    audience. At Vedaham Business Solutions, we specialize in
                    transforming your provided information into visually
                    engaging presentations. Utilizing strategic visual hierarchy
                    techniques, we ensure that important points are highlighted
                    through carefully chosen font sizes, colors, and placement.
                    Consistency is key in our approach; we maintain uniformity
                    in design elements such as fonts, colors, and styles
                    throughout your presentation. Furthermore, we structure your
                    presentation like a cohesive story, with a clear beginning,
                    middle, and end, ensuring that your audience remains engaged
                    from start to finish.
                  </>
                }
                color="#6A7C92"
                textAlign="left"
              />
              <br />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{
          p: "6%",
          backgroundImage:
            "url(https://ryse.radiantthemes.com/wp-content/uploads/2019/06/link-building-curve-banner.jpg)",
          backgroundRepeat: "no-repeat",
          objectFit: "contain",
        }}
      >
        <Grid item xs={12}>
          <Section2
            pt="5%"
            text1="The Power of Visual Hierarchy"
            text2={
              <>
                At Vedaham Business Solutions, we understand the power of
                effective presentation design in communicating your vision and
                driving meaningful engagement. Our presentation design services
                are crafted to transform your ideas into compelling narratives
                that captivate your audience and leave a lasting impression.
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="basic tabs example"
                sx={{
                  border: "solid .2px ",
                  borderColor: "lightgray",
                  "& .MuiTabs-indicator": {
                    backgroundColor: "red",
                  },
                }}
              >
                {tabLabels.map((label, index) => (
                  <Tab
                    label={label}
                    {...a11yProps(index)}
                    key={index}
                    sx={{
                      pt: "3%",
                      pb: "3%",
                      pl: "2%",
                      pr: "2%",
                      color: "#1E1666",
                      fontWeight: "600",
                      fontSize: "1.15rem",
                      textTransform: "capitalize",
                      "&.Mui-selected": {
                        color: "#1E1666",
                      },
                    }}
                  />
                ))}
              </Tabs>
            </Box>

            {tabContent.map((content, index) => (
              <CustomTabPanel value={value} index={index} key={index}>
                <Grid container spacing={5}>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{ margin: "auto" }}
                  >
                    <img src={content.img} alt="" width="100%" />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{ margin: "auto" }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TypographyText
                          Typography={content.txt1}
                          fontWeight="bolder"
                          color="#1E1666"
                          fontSize="30px"
                          // variant={!isSmallScreen ? "h5" : "h5"}
                          textAlign={!isSmallScreen ? "left" : "centre"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <hr
                          width="50px;"
                          color="red"
                          size="5"
                          style={{ margin: !isSmallScreen ? "0" : "auto" }}
                        ></hr>
                      </Grid>

                      <Grid item xs={12}>
                        <TypographyText
                          Typography={content.txt2}
                          fontSize="1.12rem"
                          color="#6A7C92"
                          textAlign={!isSmallScreen ? "left" : "centre"}
                        />
                      </Grid>
                      {content.data.map((data, index) => (
                        <Grid item lg={12} md={12} xs={12} sm={12} key={index}>
                          <ListItem disablePadding sx={{ padding: 0 }}>
                            <ListItemButton
                              style={{ backgroundColor: "transparent" }}
                              onMouseOver={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  "transparent")
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  "transparent")
                              }
                            >
                              <ListItemIcon>
                                <ArrowRightAltIcon sx={{ color: "red" }} />
                              </ListItemIcon>
                              <TypographyText
                                Typography={data.listtxt}
                                color="#827C92"
                              />
                            </ListItemButton>
                          </ListItem>
                        </Grid>
                      ))}
                      
                    </Grid>
                  </Grid>
                </Grid>
              </CustomTabPanel>
            ))}
          </Box>
        </Grid>
      </Grid>
      {Data &&
        Data.map((data, index) => (
          <LeftRigt
            key={index}
            img={data.img}
            txt1={data.txt1}
            txt2={data.txt2}
            Listcontent4={data.listContent}
          />
        ))}
  
 
    </>
  );
};

export default Linkgenration;
const Data = [
  {
    image:
      "https://ryse.radiantthemes.com/wp-content/uploads/2019/06/link-building-circle-icon1.jpg",
    Typography1: "User Engaging",
    Typography2:
      "There are many variations of lorem duos lora spassages of Lorem Ipsum available internet tend to repeat predefined.",
  },
  {
    image:
      "https://ryse.radiantthemes.com/wp-content/uploads/2019/06/link-building-circle-icon2.jpg",
    Typography1: "Web Outreach",
    Typography2:
      "There are many variations of lorem duos lora spassages of Lorem Ipsum available internet tend to repeat predefined.",
  },
  {
    image:
      "https://ryse.radiantthemes.com/wp-content/uploads/2019/06/link-building-circle-icon3.jpg",
    Typography1: "Mobile Penetration",
    Typography2:
      "There are many variations of lorem duos lora spassages of Lorem Ipsum available internet tend to repeat predefined.",
  },
  {
    image:
      "https://ryse.radiantthemes.com/wp-content/uploads/2019/06/link-building-circle-icon4.jpg",
    Typography1: "Interface Rich",
    Typography2:
      "There are many variations of lorem duos lora spassages of Lorem Ipsum available internet tend to repeat predefined.",
  },
];
const tabContent = [
  {
    img: "https://ryse.radiantthemes.com/wp-content/uploads/2019/06/link-building-tab-img1.png",
    txt1: "Font Size",
    txt2: "Emphasizing key points through size variation.",
    data: [
      {
        listtxt:
          "There are many variations of lorem duosNot all information is created equal. By varying font sizes, you can draw attention to the most important points and guide your audience through the narrative of your presentation..",
      },
    ],
  },
  {
    img: "https://ryse.radiantthemes.com/wp-content/uploads/2019/06/link-building-tab-img2.png",
    txt1: "Color Psychology",
    txt2: "Utilizing colors to evoke emotions and highlight important information.",
    data: [
      {
        listtxt:
          "Colors have the power to evoke emotions and convey messages. Strategic use of color can help emphasize key concepts and create a visually appealing experience for your audience.",
      },
    ],
  },
  {
    img: "https://ryse.radiantthemes.com/wp-content/uploads/2019/06/link-building-tab-img3.png",
    txt1: "Strategic Placement",
    txt2: "Guiding audience attention with thoughtful arrangement.  ",
    data: [
      {
        listtxt:
          "Where you place information on your slides matters. By strategically arranging elements, you can direct your audience's focus and ensure they absorb the most critical information.",
      },
    ],
  },
  {
    img: "https://ryse.radiantthemes.com/wp-content/uploads/2019/06/link-building-tab-img4.png",
    txt1: "Tracking and Reporting",
    txt2: "There are many variations of lorem passages of Lorem Ipsum available, but the majority have suffered. All the Lorem Ipsum generators. Lorem available tent to lora.",
    data: [
      { listtxt: "There are many variations of lorem duos." },
      { listtxt: "Lorem Ipsum available internet tend to repeat predefined." },
      {
        listtxt: "Spassages of available internet tend to repeat predefined.",
      },
    ],
  },
];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const tabLabels = ["Font Size", "Color Psychology", "Strategic Placement"];
