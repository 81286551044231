import { Grid } from "@mui/material";
import React from "react";
import {
  AllCards,
  Buttons,
  ImageIcon,
  Section2,
  TypographyText,
} from "../ReusableComponent/Reusab";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Homesection3 = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Grid container spacing={2} sx={{ pl: "6%", pr: "6%", pb: "6%" }}>
        <Grid item xs={12}>
          <Section2
            pt="2%"
            text1="We Provide  Services That Will Grow Your Business"
            text2={
              <>
                {" "}
                Experience unparalleled growth and success with our additional
                services tailored to meet your business needs. Partner with
                Vedaham Business Solutions and take your business to new
                heights.
              </>
            }
          />
          <div style={{ position: "absolute", right: 0, top: "150vh" }}>
            <img
              src="https://ryse.radiantthemes.com/wp-content/uploads/2020/03/1-circle-small.png"
              alt=""
              style={{ marginRight: "150px" }}
            />
          </div>
          <div style={{ position: "absolute", left: "300", top: "120vh" }}>
            <img
              src="https://ryse.radiantthemes.com/wp-content/uploads/2020/03/1-circle-large.png"
              alt=""
              style={{ marginRight: "100px" }}
            />
          </div>
        </Grid>
        {Data.map((data, index) => (
          <Grid item xs={12} lg={4} md={4} sm={12} key={index}>
            <AllCards
              cardheight="250px"
              image={data.image}
              height="200px"
              width="200px"
              backgroundColor={data.backgroundColor}
              boxShadow="9px 17px 30px 0px rgba(0,0,0,.1)"
              backgroundColor1="#FFFFFF"
              txt1color="#1E1666"
              txt2color="#6a7c92"
              txt1fontSize="22px"
              txt2fontSize="15px"
              Typography1={data.Typography1}
              Typography2={data.Typography2}
              txt2textAlign="left"
              txt1fontWeight="bolder"
              fontWeight="600"
              color="red"
            />
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        spacing={5}
        sx={{
          pl: "6%",
          pr: "6%",
          pb: "3%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item lg={6} md={7} sm={12} xs={12}>
          <ImageIcon
            txt="About Company"
            icon="http://ryse.radiantthemes.com/wp-content/uploads/2020/03/about-company-1.png"
          />
          <br /> <br />
          <Grid container>
            <Grid item xs={12}>
              <TypographyText
                Typography={
                  <>
                    Empowering Businesses Through Digital Innovation: The
                    Vedaham Business Solutions{" "}
                  </>
                }
                color="#1E1666"
                fontSize="30px"
                fontWeight="600"
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
            </Grid>
            <Grid item xs={12}>
              <hr
                width="50px;"
                color="red"
                size="5"
                style={{ margin: !isSmallScreen ? 0 : "auto" }}
              ></hr>
            </Grid>
            <Grid item xs={12}>
              <br />
              <TypographyText
                Typography={
                  <>
                    Vedaham Business Solutions empowers businesses with
                    cutting-edge solutions that transcend boundaries. We provide
                    innovative digital solutions that inspire change and foster
                    enduring relationships.
                  </>
                }
                color="#6A7C92"
                textAlign="left"
              />
              <br />
            </Grid>
            {Listcontent.map((data, index) => (
              <Grid item lg={12} md={12} xs={12} sm={12} key={index}>
                <ListItem disablePadding>
                  <ListItemButton
                    style={{ backgroundColor: "transparent" }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.backgroundColor = "white")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.backgroundColor = "transparent")
                    }
                  >
                    <ListItemIcon>
                      <img src={data.listimg} alt="number" />
                    </ListItemIcon>
                    <TypographyText
                      Typography={data.listtxt}
                      color="#1E1666"
                      fontSize="16px"
                      fontWeight="600"
                    />
                  </ListItemButton>
                </ListItem>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          lg={6}
          md={5}
          sm={12}
          xs={12}
          sx={{ margin: "auto", justifyContent: "center", textAlign: "center" }}
        >
          <img
            src="https://img.freepik.com/free-vector/gradient-devops-illustration_23-2149373211.jpg?t=st=1709627306~exp=1709630906~hmac=1bfb34e19bfefb2d0d3c3fe617d2231a024d0f8ea5aa7451c315cfced10e1ead&w=900"
            alt=""
            width="100%"
            style={{ margin: "auto" }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{
          pl: "6%",
          pr: "6%",
          pb: "2%",
          pt: "2%",
          backgroundImage:
            "url(https://ryse.radiantthemes.com/wp-content/uploads/2020/03/blog_banner.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
          // objectFit: "contain",
        }}
      >
        <Grid item xs={12}>
          <ImageIcon
            txt="Our Mission"
            icon="https://ryse.radiantthemes.com/wp-content/uploads/2020/03/our-mission-1.png"
          />
          <Section2
            text1="We Offer Complete Range of Features"
            text2={
              <>
                At Vedaham Business Solutions, we pride ourselves on empowering
                businesses with cutting-edge digital solutions, crafting
                compelling narratives, driving meaningful engagement, and
                engineering growth in the ever-evolving digital landscape, all
                to redefine success and propel your business to new heights.
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <br />
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="basic tabs example"
                sx={{
                  boxShadow: "9px 17px 30px 0px rgba(0,0,0,.1)",
                  "& .MuiTabs-indicator": {
                    backgroundColor: "red",
                  },
                }}
              >
                {tabLabels.map((label, index) => (
                  <Tab
                    label={label}
                    {...a11yProps(index)}
                    key={index}
                    sx={{
                      pl: "2%",
                      pr: "2%",
                      pt: "1.5%",
                      pb: "1.5%",
                      color: "black",
                      fontWeight: "400",
                      fontSize: "1rem",

                      textTransform: "capitalize",
                      "&.Mui-selected": {
                        color: "black",
                      },
                    }}
                  />
                ))}
              </Tabs>
            </Box>

            {tabContent.map((content, index) => (
              <CustomTabPanel value={value} index={index} key={index}>
                <Grid container spacing={5}>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{ margin: "auto" }}
                  >
                    <img src={content.img} alt="" width="100%" />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{ margin: "auto" }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TypographyText
                          Typography={content.txt1}
                          fontWeight="bolder"
                          color="#1E1666"
                          fontSize="30px"
                          // variant={!isSmallScreen ? "h5" : "h5"}
                          textAlign={!isSmallScreen ? "left" : "centre"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <hr
                          width="50px;"
                          color="red"
                          size="5"
                          style={{ margin: !isSmallScreen ? "0" : "auto" }}
                        ></hr>
                      </Grid>

                      <Grid item xs={12}>
                        <TypographyText
                          Typography={content.txt2}
                          fontSize="1rem"
                          color="#6A7C92"
                          textAlign={!isSmallScreen ? "left" : "centre"}
                        />
                        <br />
                      </Grid>
                      {content.data.map((data, index) => (
                        <Grid item lg={12} md={12} xs={12} sm={12} key={index}>
                          <TypographyText
                            Typography={
                              <>
                                <CheckCircleIcon
                                  sx={{
                                    width: "1rem",
                                    height: "1rem",
                                    color: "red",
                                  }}
                                />
                                &nbsp;
                                {data.listtxt}
                              </>
                            }
                            fontSize="22px"
                            color="#1E1666"
                            fontWeight="600"
                            textAlign={!isSmallScreen ? "left" : "center"}
                          />

                          <TypographyText
                            Typography={data.listtxt1}
                            fontSize="1rem"
                            color="#6A7C92"
                            textAlign={!isSmallScreen ? "left" : "centre"}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </CustomTabPanel>
            ))}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Homesection3;
const Data = [
  {
    backgroundColor: "#FDF8E3",
    image:
      "https://ryse.radiantthemes.com/wp-content/uploads/2020/03/business-consulting-1.png",
    Typography1: "Brand Building",
    Typography2: (
      <>
        Crafting a cohesive brand identity integrates logo, business, and
        graphic designs. A compelling logo embodies the brand essence, while
        design elements reflect personality, values, and vision. Graphic content
        reinforces brand narrative, fostering recognition and trust.
      </>
    ),
  },
  {
    backgroundColor: "#F0F3FA",
    image: "https://ryse.radiantthemes.com/wp-content/uploads/2020/03/box2.png",
    Typography1: "Web/App Development",
    Typography2: (
      <>
        Web/App Development involves creating and maintaining websites and
        mobile applications. It encompasses designing, coding, and optimizing
        user interfaces and experiences for various platforms, ensuring
        functionality and usability across devices and operating systems.
      </>
    ),
  },
  {
    backgroundColor: "#FEEFF0",
    image: "https://ryse.radiantthemes.com/wp-content/uploads/2020/03/box3.png",
    Typography1: "Media Marketing",
    Typography2: (
      <>
        Leverage social media and digital platforms to elevate brand visibility
        and engage audience effectively. Expand online reach with compelling
        content and targeted campaigns. Collaborate to amplify brand message and
        thrive.
        <br />
      </>
    ),
  },
];

const Listcontent = [
  {
    listimg: "http://ryse.radiantthemes.com/wp-content/uploads/2020/03/no1.png",
    listtxt:
      "We innovate solutions driving growth, inspiring change, and fostering relationships in tech and marketing",
  },
  {
    listimg: "http://ryse.radiantthemes.com/wp-content/uploads/2020/03/no2.png",
    listtxt:
      "With excellence, we extend globally, serving clients beyond borders.",
  },
  {
    listimg:
      " http://ryse.radiantthemes.com/wp-content/uploads/2020/03/no3.png",
    listtxt:
      "Our services span graphic design, websites, and mobile apps, meeting evolving client needs.",
  },
];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const tabLabels = [
  "Graphic Designing",
  "Presentation Design",
  "SEO",
  "Digital Marketing",
  "Website Development ",
  "Mobile App Development",
];
const tabContent = [
  {
    img: "https://ryse.radiantthemes.com/wp-content/uploads/2020/03/business-consulting-1.png",
    txt1: "Graphic Designing",
    txt2: "Our approach to graphic design transcends traditional boundaries, as we believe in the power of visual storytelling to evoke emotions and inspire action.",
    data: [
      {
        listtxt: "Comprehensive Branding Solutions",
        listtxt1:
          "From logo design to branding elements, we meticulously craft every aspect of your visual identity to reflect the essence of your brand. Through thoughtful typography, color theory, and imagery, we ensure your brand's message resonates and leaves a lasting impression.",
      },
      {
        listtxt: "Audience-Centric Design Philosophy",
        listtxt1:
          "Understanding the importance of resonating with your target audience, we tailor our designs to engage and captivate them. Leveraging visual elements that speak directly to their preferences and interests, we create aesthetically pleasing designs that drive meaningful connections with your customers.",
      },
    ],
  },

  {
    img: "https://ryse.radiantthemes.com/wp-content/uploads/2020/03/business-consulting-1.png",
    txt1: "Presentation Design",
    txt2: "Our seasoned team comprehends the significance of visually compelling presentations.",
    data: [
      {
        listtxt: "Tailored Designs",
        listtxt1:
          " We customize every design to align with your brand identity and message, guaranteeing optimal impact and engagement.",
      },
      {
        listtxt: "Creative Transformation",
        listtxt1:
          "Through meticulous attention to detail and innovative flair, we adeptly transform your concepts into compelling visual narratives.",
      },
    ],
  },

  {
    img: "https://ryse.radiantthemes.com/wp-content/uploads/2020/03/business-consulting-1.png",
    txt1: "SEO",
    txt2: "Our SEO experts employ proven strategies to improve your website's search engine rankings and increase organic traffic.",
    data: [
      {
        listtxt: "Strategic Optimization",
        listtxt1:
          "Through keyword research, content optimization, and technical enhancements, we optimize your online presence for better visibility and higher conversion rates.",
      },
      {
        listtxt: "Continuous Adaptation",
        listtxt1:
          "We stay abreast of algorithm updates and industry trends to keep your website ahead in the competitive digital landscape.",
      },
    ],
  },
  {
    img: "https://ryse.radiantthemes.com/wp-content/uploads/2020/03/business-consulting-1.png",
    txt1: "Digital Marketing",
    txt2: " Our digital marketing services encompass a comprehensive approach, utilizing a mix of social media, content marketing, and paid advertising to amplify your brand's online presence.",
    data: [
      {
        listtxt: "Targeted Campaign Development",
        listtxt1:
          "We analyze audience insights and behavior patterns to develop targeted campaigns that resonate with your target demographic.",
      },
      {
        listtxt: "Continuous Optimization",
        listtxt1:
          "By monitoring campaign performance and refining strategies, we continuously optimize your digital marketing efforts for maximum ROI.",
      },
    ],
  },
  {
    img: "https://ryse.radiantthemes.com/wp-content/uploads/2020/03/business-consulting-1.png",
    txt1: "Website Development",
    txt2: " Our website development team combines technical expertise with creative design to build visually stunning and highly functional websites.",
    data: [
      {
        listtxt: "Prioritizing User Experience",
        listtxt1:
          " We prioritize user experience, ensuring intuitive navigation and seamless interaction across all devices.",
      },
      {
        listtxt: "Focus on Scalability and Performance",
        listtxt1:
          " With a focus on scalability and performance, we create websites that grow with your business and leave a lasting impression on visitors.",
      },
    ],
  },
  {
    img: "https://ryse.radiantthemes.com/wp-content/uploads/2020/03/business-consulting-1.png",
    txt1: "Mobile App Development",
    txt2: " Our mobile app development process begins with a thorough understanding of your business goals and target audience.",
    data: [
      {
        listtxt: "Customized Solutions",
        listtxt1:
          " We design and develop custom mobile applications that offer intuitive user interfaces and seamless performance across various platforms.",
      },
      {
        listtxt: "Rigorous Testing and Optimization",
        listtxt1:
          " Through rigorous testing and optimization, we ensure that your app delivers a superior user experience and meets industry standards for security and reliability.",
      },
    ],
  },
];
