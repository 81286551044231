import * as React from "react";
import { FiberManualRecord as CircleIcon } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { Box, Divider, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TypographyText } from "../ReusableComponent/Reusab";
const Vertsteps = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{
          pl: "10%",
          pr: "10%",
          pt: "6%",
          pb: "6%",
          justifyContent: "center", // Center items horizontally
        }}
      >
        {Data.map((data, index) => (
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            key={index}
            sx={{
              position: "relative",
              paddingLeft: "24px",
              marginBottom: "0px",
              marginTop: "0",

              borderLeft: !isSmallScreen
                ? index === 1 || index === 3 || index === 5 || index === 7
                  ? "solid 2px lightgray"
                  : "none"
                : "none",
            }}
          >
            {!isSmallScreen ? (
              index === 1 || index === 3 || index === 5 || index === 7 ? (
                <CircleIcon
                  sx={{
                    position: "absolute",
                    top: "-10",
                    left: "-12px",
                    color: "#E2E5E9",
                  }} // Adjusted left to fit with the border
                />
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {index === 0 || index === 3 || index === 4 || index === 7 ? (
              <Box sx={{ p: "2%" }}>
                <div
                  data-aos={
                    index === 0 || index === 2 || index === 4 || index === 6
                      ? "fade-right"
                      : "fade-left"
                  }
                >
                  <img src={data.imageUrl} alt={data.altText} width="60%" />
                </div>
              </Box>
            ) : (
              <Box sx={{ p: "2%", mt: !isSmallScreen ? "5%" : "" }}>
                <div
                  data-aos={
                    index === 1 || index === 3 || index === 5 || index === 7
                      ? "fade-left"
                      : "fade-right"
                  }
                >
                  <TypographyText
                    Typography={data.text}
                    color="#1E1666"
                    fontSize="22px"
                    fontWeight="600"
                    textAlign={!isSmallScreen ? "left" : "centre"}
                  />
                  <TypographyText
                    Typography={data.text1}
                    color="#6A7C92"
                    textAlign={!isSmallScreen ? "left" : "centre"}
                  />
                </div>
              </Box>
            )}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Vertsteps;

const Data = [
  {
    imageUrl:
      "https://img.freepik.com/free-vector/software-developers-coding-composition-with-creative-decisions-algorithmic-complexity-documentation-by-programming-languages-isometric_1284-33290.jpg?t=st=1710476952~exp=1710480552~hmac=e3eae39795516e38f8aaca0ac27c199f630f1ec4d6b834c5f78bc6089dd80f0d&w=826",
  },
  {
    text: "2018 - Inception of Vedaham",
    text1:
      "Since our inception, we have been dedicated to providing innovative digital solutions. Our journey began with a small team of passionate individuals, and today, we stand as a growing force in the digital industry.",
  },
  {
    text: "2019 - Global Reach",
    text1:
      "Our commitment to excellence has allowed us to serve clients not just locally, but also internationally. We have proudly extended our footprint, reaching clients in various countries.",
  },
  {
    imageUrl:
      "https://media.istockphoto.com/id/1037182588/vector/global-business.jpg?s=612x612&w=0&k=20&c=9gpX1St5FyOzv9BW9j5CzptolvSApDpVo8hnG_nQTis=",
  },

  {
    imageUrl:
      "https://img.freepik.com/free-vector/packaged-software-abstract-concept-illustration_335657-3887.jpg?size=626&ext=jpg&ga=GA1.2.730086779.1709291533&semt=ais",
  },
  {
    text: "2020 - Expanding Our Service Portfolio",
    text1:
      "We have continually expanded our service offerings to meet the evolving needs of our clients. From digital marketing to website and mobile app development, we've embraced diversity to serve our clients comprehensively.",
  },

];
