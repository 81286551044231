import { Box, Card } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import img1 from "../Assets/Logo - Orange - Edited.png";
import img2 from "../Assets/LVT Logo.png";
import img3 from "../Assets/Logo1.png";
import img4 from "../Assets/Toycity logo.webp";
import img5 from "../Assets/Symphony Perfumes Logo.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const Homesection2 = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen1 = useMediaQuery(theme.breakpoints.down("sm"));
  var settings = {
    // infinite: true,

    // autoplay: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      // {
      //   breakpoint: 350,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
    ],
  };
  return (
    <Box
      className="slider-container"
      sx={{
        marginLeft: "6%",
        marginRight: "6%",
        backgroundColor: "#F5F5F5",
        transform: "translatey(-30%)",
      }}
    >
      <Slider {...settings}>
        {Data.map((data, index) => {
          return (
            <Box
              key={index}
              sx={{
                backgroundColor: "#F5F5F5",
                p: "10%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%", // Ensure the slider item takes full height
              }}
            >
              <img
                component="img"
                src={data.img}
                alt="logo"
                style={{
                  alignItems: "center",
                  margin: "auto",
                  maxWidth: isSmallScreen1
                    ? "100px"
                    : isSmallScreen
                    ? "120px"
                    : "150px",
                  // isSmallScreen ? "120px" : isSmallScreen1 ? "100px" : "150px",
                  maxHeight: "100%", // Make image take full height of its container
                  objectFit: "contain", // Adjust object-fit for proper image scaling
                }}
              />
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
};

export default Homesection2;
const Data = [
  {
    img: img1,
  },
  {
    img: img2,
  },
  {
    img: img3,
  },
  {
    img: img4,
  },
  {
    img: img5,
  },
];
