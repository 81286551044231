import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "../Comp/Header";


import Homecontent from "../Homes/Homecontent";


import Seo from "../Service/Seo";
import Webdevlopment from "../Service/Webdevlopment";
import Webdesign from "../Service/Webdesign";
import Socialmedia from "../Service/Socialmedia";
import Linkgenration from "../Service/Linkgenration";
import Marketinganalysis from "../Service/Marketinganalysis";
import Aboutus from "../About/Aboutus";
import Faq from "../FAQ/Faq";
import Contact from "../Contact/Contact";
import Freequate from "../FAQ/Freequate";
import Foter from "../Comp/Foter";
import Mobile from "../Service/Mobile";


const Navigate = () => {
  return (
    <Router>
      <div>
        <Header />
      </div>

      <Routes>
      <Route path="/freequote" element={<Freequate />}></Route> 
        <Route path="/" element={<Homecontent />}></Route> 
        <Route path="/faq" element={<Faq />}></Route> 
        <Route path="/contactus" element={<Contact/>}></Route> 
        <Route path="/seo" element={<Seo />}></Route> 
        <Route path="/aboutus" element={<Aboutus />}></Route> 
        <Route path="/web-design" element={<Webdesign />}></Route>
        <Route path="/graphics-design" element={<Socialmedia/>}></Route>
        <Route path="/web-development" element={<Webdevlopment />}></Route> 
        <Route path="/Presentation-design" element={<Linkgenration />}></Route>
        <Route path="/digital-marketing" element={<Marketinganalysis />}></Route> 
        <Route path="/mobile-app-development" element={<Mobile />}></Route> 
      </Routes>

      <div>
        {/* <Footer /> */}
        <Foter/>
      </div>
    </Router>
  );
};

export default Navigate;
