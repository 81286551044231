import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
} from "@mui/material";
import React from "react";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Slider from "react-slick";

import {
  ImageIcon,
  Section2,
  TypographyText,
} from "../ReusableComponent/Reusab";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 2000,
};
const Testmonial = () => {
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          backgroundPosition: "centre",
          backgroundRepeat: "no-repeat",
          pl: "10%",
          pr: "10%",
          pt: "3%",
          pb: "2%",
        }}
      >
        <Grid item xs={12}>
          <ImageIcon
            txt="Client Testmonial"
            icon="https://ryse.radiantthemes.com/wp-content/uploads/2020/03/our-mission-1.png"
          />
          <Section2 text1="Profiles of The Powerful Advertising" />
        </Grid>

        <Grid item xs={12}>
          <Slider {...settings} className="slickheight">
            {Data.map((post, index) => (
              <div key={index}>
                <Card
                  sx={{
                    boxShadow: "none",
                    backgroundColor: "transparent",
                    backgroundImage:
                      "url(https://ryse.radiantthemes.com/wp-content/uploads/2020/03/testimonials-bg.png)",
                  }}
                >
           
                  <CardContent>
                    <TypographyText
                      Typography={post.text}
                      textAlign={"centre"}
                      color="#6A7C92"
                      fontStyle="italic"
                      fontSize="1.15rem"
                    />
                    <br />

                    <TypographyText
                      Typography={post.txt1}
                      fontWeight="600"
                      color="black"
                      fontSize="1.1rem"
                    />
                    <TypographyText
                      Typography={post.txt2}
                      color="red"
                      fontSize=".9rem"
                    />
                  </CardContent>
                </Card>
              </div>
            ))}
          </Slider>
        </Grid>
      </Grid>
    </>
  );
};

export default Testmonial;
const Data = [
  {

    text: "I can't recommend [Digital Marketing Company Name] highly enough! Their team's expertise in web development, digital marketing, presentation design, and graphic design services has been instrumental in taking our business to the next level. They seamlessly integrated our vision into a stunning website, optimized it for maximum visibility, and crafted captivating digital marketing campaigns that have significantly boosted our online presence. Their creativity and attention to detail shine through in every project, and their dedication to delivering results is truly commendable. Thanks to [Digital Marketing Company Name], we've seen a remarkable increase in traffic, engagement, and conversions. Partnering with them has been one of the best decisions we've made for our business!",
    txt1: "Rakesh Nair",
    txt2: "My Family Fitness, Dubai",
  },
  {

    text: (
      <>
        "I am immensely impressed with the comprehensive solutions provided by
        Vedaham Business Solutions. Their expertise in digital marketing,
        website development, graphic design, and presentation design is
        unmatched. Their team’s professionalism, attention to detail, and
        dedication to delivering high-quality results have significantly
        contributed to our business success. I highly recommend Vedaham Business
        Solutions to any organization seeking top-notch services in the realm of
        digital presence and design."
      </>
    ),
    txt1: "Rajiv Bhasker",
    txt2: "Toycity, KSA",
  },
  {

    text: (
      <>
        "Exceptional craftsmanship and creativity define our experience with
        Vedaham Business Solutions. Their graphic designing and presentation
        design services seamlessly transformed our vision into stunning visual
        narratives. Their professionalism and attention to detail surpassed our
        expectations, elevating our brand and communication materials to new
        heights. Highly recommended."
      </>
    ),
    txt1: "Vinoj V",
    txt2: "Sun Gaurd, Dubai",
  },
];
