import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Homesection from "./Homesection";
import Homesection2 from "./Homesection2";
import Homesection3 from "./Homesection3";
import Homesection4 from "./Homesection4";
import Testmonial from "./Testmonial";

const Homecontent = () => {
  useEffect(() => {
    window.scrollTo(0, 0); 
}, []);
  return (
    <div
      style={{
        backgroundColor: "#FEFEFE",
      }}
    >
      <Homesection />
      <Homesection3 />
      <Homesection4 />
      <Testmonial />
    </div>
  );
};

export default Homecontent;
